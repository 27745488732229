<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Экспорт сведений для расчёта показателей</span>
    </v-card-title>

    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <!--                    <v-autocomplete label="Показатели"-->
          <!--                                    item-text="name"-->
          <!--                                    return-object-->
          <!--                    ></v-autocomplete>-->

          <v-select style="width:400px"
                    :items="periods"
                    v-model="period"
                    label="Период мониторинга"
          ></v-select>

        </v-container>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn class="btn btn-outline"
             text
             @click="$emit('close')">
        Закрыть
      </v-btn>
      <v-btn class="btn btn-primary"
             @click="submit()">
        Скачать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>


import api from "@/modules/api";
import {orgMonitorConfig} from "@/modules/OrgMonitorConfig";

export default {
  name: "OrgMonitorCheckListExportDialog",
  props: ['region', 'userDepartment', 'formData'],
  data: () => ({
    exportType: "XLSX",
    periods: [
      {
        text: '1 января 2023 - 30 июня 2023',
        value: 0
      }
    ],
    period: 0,
    beginDate: null,
    endDate: null,
    maxDate: new Date().toJSON().slice(0, 10)
  }),
  methods: {
    async submit() {
      await this.loadValues()
      let query = {
        headerCells: [
          {
            caption: "Раздел",
            width: 10000,
          },
          {
            caption: "Показатель",
            width: 10000,
          },
          {
            caption: "Группа",
            width: 10000,
          },
          {
            caption: "Вопрос",
            width: 10000,
          },
          {
            caption: "Комментарий",
            width: 10000,
          },
          {
            caption: "Приложенные файлы",
            width: 10000,
          },
          {
            caption: "Контроль (комментарий)",
            width: 10000,
          },
          {
            caption: "Контроль (оценка)",
            width: 10000,
          },
        ],
        rows: []
      }
      for(let section of orgMonitorConfig.sections){
        for(let indicator of section.indicators){
          for(let group of indicator.groups){
            for(let question of group.questions){
              let value = this.getValue(section.id, indicator.id, group.id, question.id, this.period)
              query.rows.push([
                { cellDataType: "STRING", value: section.name },
                { cellDataType: "STRING", value: indicator.name },
                { cellDataType: "STRING", value: group.name },
                { cellDataType: "STRING", value: question.name },
                { cellDataType: "STRING", value: value?.comment },
                { cellDataType: "STRING", value: value?.attachments.length },
              ])
            }
          }
        }
      }
      await this.$downloadApi("Экспорт.xlsx","/export/xlsx", query, true);
      this.$emit('close');
    },

    getValue(sectionId, indicatorId, groupId, questionId, period) {
      if(!this.checklistValues) return null
      return this.checklistValues.find(
          e => e.period === period && e.sectionId === sectionId && e.indicatorId === indicatorId && e.groupId === groupId && e.questionId === questionId
      )
    },

    async loadValues() {
      let req = await api.get("/monitoring2023/org/checklistValues/" + this.userDepartment.id)
      if (req.ok) {
        this.checklistValues = req.payload;
        console.log(this.checklistValues)
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rating-popup {
  .container {
    padding: 0;
  }
}

.v-dialog {
  .v-card {
    .v-card__actions {
      justify-content: stretch;
      gap: 20px;
      padding: 0 24px 20px 24px;
      width: 100%;

      .v-btn.v-btn + .v-btn {
        margin: 0;
      }

      .btn {
        flex: 1 1 50%;
      }
    }
  }
}
</style>
