<template>
  <div v-cloak v-if="apiLoaded">
    <template>
      <div class="block">
        <div class="report-list__item"
             style="margin-bottom: 15px"
             v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY'].includes($user.role)"
        >
          <a @click="downloadAsync(CHECKLISTS_REPORT)">
            Выгрузить чек-листы
          </a>
          <br>
        </div>

        <div class="report-list"
             v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD'].includes($user.role)"
        >
          <div class="report-list__item" v-for="report in FCK_REPORTS" :key="'report_' + report.id">
            <div class="report-list__title">
              <span>{{ report.name }}</span>
            </div>
            <a @click="downloadAsync(report)">
              <div class="report-list__download"></div>
            </a>
          </div>
        </div>

        <div class="report-list"
             v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY', 'ROLE_CZN_MANAGER'].includes($user.role)"
        >
          <div class="report-list__item" v-for="report in REGIONAL_REPORTS" :key="'report_' + report.id">
            <div class="report-list__title">
              <span>{{ report.name }}</span>
            </div>
            <a @click="downloadAsync(report)">
              <div class="report-list__download"></div>
            </a>
          </div>
        </div>

        <div class="report-list"
             v-if="regionAndDepartmentInfo.department?.type === 'territorial_czn' &&
                   ['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY', 'ROLE_CZN_MANAGER', 'ROLE_CZN_TERRITORIAL'].includes($user.role)"
        >
          <div class="report-list__item" v-for="report in TERRITORIAL_CZN_REPORTS" :key="'report_' + report.id">
            <div class="report-list__title">
              <span>{{ report.name }}</span>
            </div>
            <a @click="downloadAsync(report)">
              <div class="report-list__download"></div>
            </a>
          </div>
        </div>

        <div class="report-list"
             v-if="regionAndDepartmentInfo.department?.type === 'manager_czn' &&
                   ['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY', 'ROLE_CZN_MANAGER'].includes($user.role)"
        >
          <div class="report-list__item" v-for="report in MANAGER_CZN_REPORTS" :key="'report_' + report.id">
            <div class="report-list__title">
              <span>{{ report.name }}</span>
            </div>
            <a @click="downloadAsync(report)">
              <div class="report-list__download"></div>
            </a>
          </div>
        </div>
      </div>
    </template>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </div>
</template>

<script>

import LoaderDialog from "@/components/elements/LoaderDialog";
import {orgMonitorConfig} from "@/modules/OrgMonitorConfig";
import api from "@/modules/api";

export default {
  name: "OrgMonitorReports",
  components: {LoaderDialog},
  props: ['regionAndDepartmentInfo', 'period'],
  watch: {
    regionAndDepartmentInfo: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      },
      deep: true
    },
    period: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      }
    }
  },

  data: () => ({
    apiLoaded: false,
    showLoaderDialog: false,
    domain: 'org_monitor',
    downloadTaskId: null,
    CHECKLISTS_REPORT: { id : 16 },
    TERRITORIAL_CZN_REPORTS: [
      {
        id: 14,
        name: 'Отчет территориального центра занятости населения'
      },
    ],
    MANAGER_CZN_REPORTS: [
      {
        id: 15,
        name: 'Отчет управляющего центра занятости населения'
      },
    ],
    REGIONAL_REPORTS: [
      {
        id: 2,
        name: 'Сводный отчет о заполнении чек-листов и подтверждающих документов по управляющему центру занятости населения и территориальным центрам занятости населения субъекта РФ'
      },
      {
        id: 1,
        name: 'Сводный отчет о контроле заполнения чек-листов и подтверждающих документов по управляющему центру занятости населения и территориальным центрам занятости населения субъекта РФ'
      },
      {
        id: 13,
        name: 'Сводный отчет по субъекту РФ в разрезе управляющего и территориальных центров занятости населения'
      },
    ],
    FCK_REPORTS: [
      {
        id: 4,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к организационной структуре государственных учреждений службы занятости населения в разрезе субъектов РФ'
      },
      {
        id: 5,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к организации предоставления государственных услуг в области содействия занятости населения, иных государственных и муниципальных услуг (или их части), негосударственных услуг и мер поддержки, а также сервисов в разрезе субъектов РФ'
      },
      {
        id: 6,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к деятельности государственного учреждения службы занятости населения при взаимодействии с гражданами и работодателями в разрезе субъектов РФ'
      },
      {
        id: 7,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к системе управления клиентским опытом в разрезе субъектов РФ'
      },
      {
        id: 8,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к организации процессов управления персоналом государственных учреждений службы занятости населения в разрезе субъектов РФ'
      },
      {
        id: 9,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к информационному сопровождению деятельности государственных учреждений службы занятости населения в разрезе субъектов РФ'
      },
      {
        id: 10,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к расположению центров занятости населения, помещениям, в которых они функционируют и оснащению рабочих мест в разрезе субъектов РФ'
      },
      {
        id: 11,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к системам информатизации и автоматизации государственных учреждений службы занятости населения в разрезе субъектов РФ'
      },
      {
        id: 12,
        name: 'Сводный отчет по показателям исполнения Стандарта в части требований к системе контроля и оценки качества деятельности государственного учреждения службы занятости населения по осуществлению полномочий в области содействия занятости населения в разрезе субъектов РФ'
      },
      {
        id: 3,
        name: 'Сводный отчет о заполнении чек-листов и подтверждающих документов в разрезе государственных учреждений службы занятости населения (субъектов РФ)'
      },
    ],
    reports: []
  }),

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  methods: {
    getCznInfo() {
      return this.regionAndDepartmentInfo.region.departments.find(e => e.type === 'oiv')
    },

    async downloadAsync(report) {
      try {
        if (!report?.id) {
          alert('Отчет не найден!')
          return
        }
        this.showLoaderDialog = true;
        const cznParam = this.regionAndDepartmentInfo.department ? `&czn_id=${this.regionAndDepartmentInfo.department.id}` : ''
        const response = (await api.get(`/reports/generate/${this.domain}/${report.id}?report_title=${report.name}&period=${this.period}&region_code=${this.regionAndDepartmentInfo.region.code}${cznParam}`)).payload;
        this.downloadTaskId = response.task_id;
        await this.checkTaskStatus();
      } catch (error) {
        console.error('Error generating report:', error);
        alert('Ошибка при генерации отчета!');
        this.showLoaderDialog = false;
      }
    },

    async checkTaskStatus() {
      try {
        const response = (await api.get(`/reports/status/${this.downloadTaskId}`)).payload;
        if (response.status === 'SUCCESS') {
          await this.downloadFile();
        } else {
          setTimeout(this.checkTaskStatus, 2000);
        }
      } catch (error) {
        console.error('Error checking task status:', error);
        alert('Ошибка при проверке статуса задачи!');
        this.showLoaderDialog = false;
      }
    },

    async downloadFile() {
      try {
        const blob = (await api.get(`/reports/download/${this.downloadTaskId}`, null, 'blob')).payload;
        this.$downloadBlob(blob, `Отчет.xlsx`);
        this.showLoaderDialog = false;
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('Ошибка при скачивании файла!');
        this.showLoaderDialog = false;
      }
    },

    genericCznDescription(name) {
      return "Название отчета: " + name +
          "\nСубъект РФ: " + this.regionAndDepartmentInfo.region.name +
          "\nЦЗН: " + this.userDepartment?.fullName +
          "\nАдрес ЦЗН: " + this.regionAndDepartmentInfo.department?.address +
          "\nТип ЦЗН: " + (this.userDepartment?.type === 'territorial_czn' ? 'Территориальный ЦЗН' : this.userDepartment?.type === 'manager_czn' ? 'Управляющий ЦЗН' : '')

    },

    genericRegionDescription(name) {
      return "Название отчета: " + name +
          "\nСубъект РФ: " + this.regionAndDepartmentInfo.region.name
    },

    addReportInfo(query, description) {
      query.headerCells.push({
        caption: "Сведения об отчете",
        width: 15000,
      })

      if (query.rows[0]) {
        query.rows[0].push({
          cellDataType: "STRING",
          value: description
        })
      }
    },

    oneCznRows(value) {
      let rows = []
      for (let section of orgMonitorConfig.sections) {
        for (let indicator of section.indicators) {
          rows.push([
            {cellDataType: "STRING", value: section.name},
            {cellDataType: "STRING", value: indicator.name},
            {cellDataType: "STRING", value},
          ])
        }
      }
      return rows
    },

    allCznRows(value) {
      let rows = []
      for (let section of orgMonitorConfig.sections) {
        for (let indicator of section.indicators) {
          let rowCells = [
            {cellDataType: "STRING", value: section.name},
            {cellDataType: "STRING", value: indicator.name}
          ]
          for (let i = 0; i < this.regionAndDepartmentInfo.region?.departments.length; i++) {
            rowCells.push({cellDataType: "STRING", value})
          }
          rows.push(rowCells)
        }
      }
      return rows
    },

    regionsRows(value) {
      let rows = []
      for (let section of orgMonitorConfig.sections) {
        for (let indicator of section.indicators) {
          let rowCells = [
            {cellDataType: "STRING", value: section.name},
            {cellDataType: "STRING", value: indicator.name}
          ]
          for (let i = 0; i < this.$cznDepartmentsByRegionList.length; i++) {
            rowCells.push({cellDataType: "STRING", value})
          }
          rows.push(rowCells)
        }
      }
      return rows
    },

    oneCznHeaderCells() {
      return [
        {
          caption: "Раздел",
          width: 10000,
        },
        {
          caption: "Показатель",
          width: 10000,
        },
        {
          caption: "Значение",
          width: 5000,
        }
      ]
    },

    allCznHeaderCells() {
      let cells = [{
        caption: "Раздел",
        width: 10000,
      },
        {
          caption: "Показатель",
          width: 10000,
        },]
      for (let department of this.regionAndDepartmentInfo.region.departments) {
        cells.push({
          caption: department.name,
          width: 10000,
        })
      }
      return cells
    },

    regionsHeaderCells() {
      let cells = [{
        caption: "Раздел",
        width: 10000,
      },
        {
          caption: "Показатель",
          width: 10000,
        },]
      for (let region of this.$cznDepartmentsByRegionList) {
        cells.push({
          caption: region.name,
          width: 10000
        })
      }
      return cells
    },


    initialize() {
    }
  },

  async beforeMount() {
    this.initialize()
    this.apiLoaded = true;
  }
}
</script>

<style lang="scss">
@import "../../styles/main";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}

.clientindex {
  div.blue {
    background: #E1F0FA !important;
    color: #2F80ED !important;
  }

  div.green {
    background: #E2F5EA !important;
    color: #27AE60 !important;
  }

  div.yellow {
    background: #FCF4DB !important;
    color: #F2994A !important;
  }

  div.darkblue {
    background: #E5EBF5 !important;
    color: #0033A0 !important;
  }

  .badge {
    height: 30px;
    margin-left: 4px;
    margin-right: 0;
    font-size: 13px;
    font-weight: 700;
  }

  .v-slide-group__content.v-tabs-bar__content {
    justify-content: space-around !important;
  }

  .v-window-item {
    padding-top: 50px;
  }

  .v-expansion-panel .level2 {
    padding-top: 5px;

    .v-expansion-panel-content {
      padding-left: 44px;
    }
  }

  .v-expansion-panel-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    margin: 10px 0;
  }

  .list .list-item-wrap .list-item-name {
    max-width: none;

    .toggleSwitch {
      align-self: center;
    }
  }

  .list .list-item-wrap .list-item {
    justify-content: flex-start;

    .v-progress-circular {
      margin-left: 0;

      .v-progress-circular__info {
        font-size: 16px;
        font-weight: 600;
        display: block;

        .text-caption {
          font-size: 10px !important;
          vertical-align: baseline;
          display: inline;
        }
      }
    }
  }

  .list-item-wrap .list-item-wrap {
    border: none;
    padding-bottom: 5px;
  }
}

.graph-wrap {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 30px 30px 0 30px;

  h3 {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    text-align: center;
    margin-bottom: 10px;
  }

  .graph-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 10px;

    div:first-child {
      font-size: 36px;
      line-height: 30px;

      span {
        font-size: 12px;
        vertical-align: text-top;
      }
    }

    div:last-child {
      font-weight: 600;
      font-size: 16px;
      color: #1C1C1F;
    }

    &.text-blue {
      color: #2F80ED;

      div:last-child span {
        color: #2F80ED;
      }
    }

    &.text-orange {
      color: #F2994A;

      div:last-child span {
        color: #F2994A;
      }
    }

  }

  &.checklist-wrap {
    padding: 20px;
    display: flex;
    gap: 30px;
    background: #F3F3F3;
    border-radius: 20px;
    min-height: 130px;

    > div:first-child {
      width: 140px;

      div {
        color: #2F80ED;
        font-size: 36px;
        line-height: 30px;
        font-weight: 600;

        span {
          vertical-align: text-top;
          font-size: 12px;
          color: #2F80ED;
        }
      }
    }

    .checklist > div {
      display: flex;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      gap: 10px;

      span {
        width: 14px;
      }

      svg {
        margin-top: 4px;
      }

      &.checked {
        color: #0033A0;
      }
    }

    .v-progress-circular__info {
      font-size: 16px;
      display: block;
      font-weight: 600;

      .text-caption {
        font-size: 7px !important;
        vertical-align: baseline;
      }
    }
  }
}

.line-wrap {
  border: 1px solid #E8E8E9;
  border-radius: 4px;
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
