import api from "@/modules/api";

const VERBOSE = false


/**
 *
 * @returns {Promise<Object>}
 */
export async function get(path) {
    const req = await api.get(path)
    if (req.ok) {
        if (VERBOSE) console.log("GET ", path, "status", req.ok ? "ok" : "fail", "result", req.payload)
        return req.payload
    } else {
        const err = 'Ошибка загрузки данных ' + path
        alert(err)
        console.error(err)
        throw(err)
    }
}

/**
 *
 * @returns {Promise<Object>}
 */
export async function post(path, obj, querySpec) {
    const req = await api.postJson(path, obj, querySpec)
    if (req.ok) {
        if (VERBOSE) console.log("POST ", path, "status", req.ok ? "ok" : "fail", "result", req.payload)
        return req.payload
    } else {
        const err = 'Ошибка cохранения данных ' + path
        alert(err)
        console.error(err)
        throw(err)
    }
}

/**
 *
 * @returns {Promise<Object>}
 */
export async function httpDelete(path, querySpec) {
    const req = await api.delete(path, querySpec)
    if (req.ok) {
        if (VERBOSE) console.log("DELETE ", path, "status", req.ok ? "ok" : "fail", "result", req.payload)
        return req.payload
    } else {
        const err = 'Ошибка удаления данных ' + path
        alert(err)
        console.error(err)
        throw(err)
    }
}
