import {get, httpDelete, post} from "@/modules/remote";

export async function loadConfigsByMonitoringType(monitoringType) {
    return get(`/monitoring2023/config/bytype/${monitoringType}`)
}

export async function loadAllConfigs() {
    return get(`/monitoring2023/config/`)
}

export async function loadById(id) {
    let configs = await loadAllConfigs()
    return configs.find(e=>e.id==id)
}

export async function saveConfig(config) {
    return post(`/monitoring2023/config/`, config, null)
}

export async function deleteConfigById(id){
    return httpDelete(`/monitoring2023/config/${id}`, null)
}

export const monitoringTypes = [
    {value: 'ORG', text: 'Стандарт организации деятельности'},
    {value: 'PROCESS', text: 'Стандарты процессов'},
    {value: 'RESULT', text: 'Показатели эффективности деятельности'},
    {value: 'INTEGRAL', text: 'Интегральный рейтинг'}
]

export const periods = [
    {
        text: '1 января 2024 - 30 июня 2024 г.',
        value: 6
    },
    {
        text: '1 января 2023 - 31 декабря 2023',
        value: 5
    },
    {
        text: '1 января 2022 - 31 декабря 2022',
        value: 3
    },
    {
        text: '1 января 2021 - 31 декабря 2021',
        value: 1
    }
]
