import {get, post} from "@/modules/remote";
import api from "@/modules/api";
import {getCznIndicatorsByIndicatorId} from "@/modules/api.indicators";
import {getActiveDomainConfigs} from "@/modules/api.configs";

let departmentsByRegion
export function setDepartmentsByRegion(val) {
    departmentsByRegion = JSON.parse(JSON.stringify(val))
}
let period
export function setPeriod(val) {
    period = val
}

const domain = "org_monitor"
const domainRating = "org_monitor_rating"

export const periods = [
    {
        text: 'январь - декабрь 2024 г.',
        value: 7
    },
    {
        text: 'январь - июнь 2024 г.',
        value: 6
    },
    {
        text: 'январь - декабрь 2023 г.',
        value: 5
    },
    {
        text: 'январь - июнь 2023 г.',
        value: 4
    }
]

export async function loadConfig() {
    return (await getActiveDomainConfigs(domain)).payload[0].content
}

export async function loadConfigRating() {
    return (await getActiveDomainConfigs(domainRating)).payload[0].content
}

export async function loadValuesByDepartmentIdAndPeriod(departmentId, period) {
    return get(`/monitoring2023/org/checklistValues/byDepartment/${departmentId}/${period}`)
}

export async function loadValuesByRegionCodeAndPeriod(regionCode, period) {
    return get(`/monitoring2023/org/checklistValues/byRegion/${regionCode}/${period}`)
}

export async function saveValues(valueList) {
    return post(`/monitoring2023/org/checklistValues/`, valueList, null)
}

export async function loadAttachmentsByDepartmentIdAndPeriod(departmentId, period) {
    return get(`/monitoring2023/org/checklistValues/attachments/byDepartment/${departmentId}/${period}`)
}

export async function loadAttachmentsByRegionCodeAndPeriod(regionCode, period) {
    return get(`/monitoring2023/org/checklistValues/attachments/byRegion/${regionCode}/${period}`)
}

export async function saveAttachments(attachmentList) {
    return post(`/monitoring2023/org/checklistValues/attachments/`, attachmentList, null)
}

export async function getStage() {
    let req = await api.get(`/monitoring2023/org/stage`, 'text')
    return req.payload
}

export function getCznsInRegionCount(regionCode) {
    return departmentsByRegion.find(e => e.code === regionCode)?.departments.filter(e => e.type === 'territorial_czn').length
}

function normalizeRating(rating) {
    rating = rating.map(e => [e[0], e[1] > 0 ? e[1] * 100 : 0])
    rating.sort((a, b) => a[1] <= b[1] ? 1 : -1)
    return rating
}

export async function getRating(indicatorId) {
    const values = parseValuesToFloat((await getCznIndicatorsByIndicatorId(domainRating, 0, indicatorId)).payload)

    const rating = departmentsByRegion.map(e => {
        const value = values.find(v => v.regionCode === e.code && +v.period === period)?.value
        return [e.name, value]
    })

    return normalizeRating(rating)
}

export function parseValuesToFloat(values) {
    return values.map(item => {
        return {
            ...item,
            value: parseFloat(item.value)
        };
    });
}