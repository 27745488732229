import { render, staticRenderFns } from "./OrgMonitorControlDialog.vue?vue&type=template&id=41495134&scoped=true"
import script from "./OrgMonitorControlDialog.vue?vue&type=script&lang=js"
export * from "./OrgMonitorControlDialog.vue?vue&type=script&lang=js"
import style0 from "./OrgMonitorControlDialog.vue?vue&type=style&index=0&id=41495134&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41495134",
  null
  
)

export default component.exports